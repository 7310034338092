const luxPopupToggles = document.querySelectorAll("*[data-toggle='lux-popup']");
const luxPopups = document.querySelectorAll("div.lux-popup");
let luxToasts = document.querySelectorAll("div.lux-toast");
let delayTimer;

const luxPopup = {
    popupClose(popup) {
        const toggles = popup.querySelectorAll("*[data-dismiss='lux-popup']");

        toggles.forEach(function (popupClose) {
            popupClose.addEventListener("click", function () {
                popup.classList.remove("active");
                popup.setAttribute("aria-hidden", "true");

                document.querySelector("html").classList.remove("no-scroll");
            });
        });
    },
    popupShow(popup) {
        popup = document.getElementById(popup);

        popup.classList.toggle("active");
        popup.setAttribute("aria-hidden", "false");

        if (window.innerWidth < 540 || popup.classList.contains("lux-modal")) {
            document.querySelector("html").classList.add("no-scroll");
        }
    },
    toastDelayTimer(toast, state) {
        clearTimeout(delayTimer);
        const toastDelay = toast.getAttribute("data-delay");

        if (state === "add") {
            delayTimer = setTimeout(function () {
                toast.classList.add("active");
            }, toastDelay);
        } else {
            delayTimer = setTimeout(function () {
                toast.classList.remove("active");
            }, toastDelay);
        }
    },
    toastDelay(toast) {
        if (toast.getAttribute("data-show")) {
            if (toast.getAttribute("data-delay")) {
                if (toast.getAttribute("data-show") === "true") {
                    toast.classList.add("active");
                    this.toastDelayTimer(toast, "remove");
                } else if (toast.getAttribute("data-show") === "false") {
                    this.toastDelayTimer(toast, "add");
                }
            }
        } else if (toast.getAttribute("data-delay")) {
            this.toastDelayTimer(toast, "remove");
        }
    },
    toastShow(toast) {
        luxToasts = document.querySelectorAll(".lux-toast");

        if (toast.classList.contains("active")) {
            toast.classList.remove("active");

            for (let toast of luxToasts) {
                toast.classList.remove("active");
            }

            setTimeout(function () {
                toast.classList.add("active");
            }, 300);
        } else {
            for (let toast of luxToasts) {
                toast.classList.remove("active");
            }

            toast.classList.add("active");
        }

        this.toastDelay(toast);
        this.popupClose(toast);
    },
};

window.luxPopup = luxPopup;

luxPopupToggles.forEach(function (toggle) {
    const popupTarget = document.getElementById(
        toggle.getAttribute("data-target"),
    );

    toggle.addEventListener("click", function () {
        luxPopup.popupShow(toggle.getAttribute("data-target"));
    });

    popupTarget?.addEventListener("click", function (e) {
        if (
            e.target === this &&
            (popupTarget.classList.contains("lux-panel") ||
                popupTarget.classList.contains("terms-modal"))
        ) {
            popupTarget.classList.remove("active");
            popupTarget.setAttribute("aria-hidden", "true");
            document.querySelector("html").classList.remove("no-scroll");
        }
    });
});

luxPopups.forEach(function (popup) {
    popup = document.getElementById(popup.getAttribute("id"));
    if (popup) {
        luxPopup.popupClose(popup);
    }
});

luxToasts.forEach(function (toast) {
    toast = document.getElementById(toast.getAttribute("id"));
    luxPopup.toastDelay(toast);
});
